import { Formik, FormikConfig } from 'formik'
import React from 'react'
import Checkboxes from '../../../../../components/Forms/Checkboxes'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { usePositionFilterLookups } from 'src/queries'

export interface FilterValues {
  contract: string[]
  status: string[]
}

export interface FiltersProps {
  onSubmit: FormikConfig<FilterValues>['onSubmit']
  initialState?: FilterValues
  isOpen: boolean
  toggleOpen: () => void
}

const Filters: React.FC<FiltersProps> = (props) => {
  const { initialState, isOpen, toggleOpen } = props
  const handleClose = toggleOpen
  const lookups = usePositionFilterLookups()
  const defaultValues = {
    contract: [],
    status: []
  }
  const initialValues: FilterValues = initialState ?? defaultValues
  return (
    <>
      <Modal isOpen={isOpen} toggle={handleClose} scrollable backdrop='static'>
        <Formik
          initialValues={initialValues}
          onSubmit={props.onSubmit}
        >
          {(props) => {
            const filter = (): void => {
              toggleOpen()
              void props.submitForm()
            }
            const handleClear = (): void => {
              props.resetForm(defaultValues)
              void props.setValues(defaultValues)
            }
            return (
              <>
                <ModalHeader toggle={handleClose}>Position Filters</ModalHeader>
                <ModalBody>
                  <form onSubmit={props.handleSubmit}>
                    <Checkboxes options={lookups.Position_Status} label='Status' />
                    <Checkboxes options={lookups.Cat_Contract} label='Contract' />
                  </form>
                </ModalBody>
                <ModalFooter>
                  <Button color='link' onClick={handleClear}><small>Clear All</small></Button>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button color='primary' onClick={filter}>
                    <FontAwesomeIcon icon={faFilter} /> Filter
                  </Button>
                </ModalFooter>
              </>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}

export default Filters
