import React from 'react'
import InputBase from './InputBase'
import { FieldHookConfig, useField } from 'formik'
import { Input as BSInput } from 'reactstrap'
import { InputType } from 'reactstrap/types/lib/Input'
import { getFieldError } from 'src/helpers/formHelpers'

export type InputProps = FieldHookConfig<any> & {
  label: string
  type: InputType
  cols?: number
  rows?: number
  required?: boolean
}

const Input: React.FC<InputProps> = (props) => {
  const { label, type, cols, rows, required, ...rest } = props
  const [field, meta] = useField({ type, ...rest })
  const { error, hasError } = getFieldError(meta)

  return (
    <InputBase label={label} name={field.name} error={error} required={required}>
      <BSInput {...field} invalid={hasError} id={field.name} type={type ?? 'text'} cols={cols} rows={rows} />
    </InputBase>
  )
}

export default Input
export { Input }
