/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query attachmentById($id: MongoID!) {\n    attachments {\n      byId(_id: $id) {\n        _id,\n        filename\n        type\n        subtype\n      }\n    }\n  }\n": types.AttachmentByIdDocument,
    "\n  query Search($page: Int, $perPage: Int, $filter: GlobalSearchFilterArgs) {\n    search(page: $page, perPage: $perPage, filter: $filter) {\n      count\n      items {\n        _id\n        badges {\n          title\n          value\n        }\n        category\n        score\n        title\n        url\n      }\n      pageInfo {\n        currentPage\n        hasNextPage\n        hasPreviousPage\n        itemCount\n        pageCount\n        perPage\n      }\n    }\n  }\n": types.SearchDocument,
    "\n  query UserSelf {\n    user {\n      self {\n        _id\n        email\n        _role {\n          defaultPage\n          title\n          _permissions {\n            _id\n            key\n          }\n        }\n        defaultPage\n        createdBy\n        updatedBy\n      }\n    }\n  }\n": types.UserSelfDocument,
    "\n  mutation mutateAnalyticsCreateOne($record: CreateOneAnalyticsInput!) {\n    analytics {\n      createOne(record: $record) {\n        recordId\n      }\n    }\n  }\n": types.MutateAnalyticsCreateOneDocument,
    "\n  query attachmentSubtypes($filter: FilterFindManyAttachment_SubtypeInput) {\n    attachmentSubtypes {\n      many(filter: $filter) {\n        _id\n        name\n      }\n    }\n  }\n": types.AttachmentSubtypesDocument,
    "\n  query attachments($filter: FilterFindManyAttachmentInput, $sort: SortFindManyAttachmentInput, $limit: Int) {\n    attachments {\n      many(filter: $filter, sort: $sort, limit: $limit) {\n        _id\n        url\n        filename\n        _subtype {\n          name\n        }\n        link\n        _link {\n          ... on Position {\n            id\n            title\n            keywords\n            _contract {\n              name\n            }\n          }\n        }\n        updatedAt\n        _updatedBy {\n          name\n        }\n      }\n    }\n  }\n": types.AttachmentsDocument,
    "\n  mutation mutateRemoveAttachment($id: MongoID!) {\n    attachments {\n      removeById(_id: $id) {\n        recordId\n      }\n    }\n  }\n": types.MutateRemoveAttachmentDocument,
    "\n  mutation mutateUpdateAttachment($id: MongoID!, $record: UpdateByIdAttachmentInput!) {\n    attachments {\n      updateById(_id: $id, record: $record) {\n        recordId\n      }\n    }\n  }\n": types.MutateUpdateAttachmentDocument,
    "\n  query candidateContractualDocumentById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          nda_signed {\n            staffing_agency\n            candidate\n          }\n        }\n      }\n    }\n  }\n": types.CandidateContractualDocumentByIdDocument,
    "\n  query candidateCreatedUpdatedById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          _createdBy {\n            name\n          }\n          _updatedBy {\n            name\n          }\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n": types.CandidateCreatedUpdatedByIdDocument,
    "\n  query candidateDetailsById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          location\n          name {\n            first\n            last\n          }\n          received_on\n          type\n          _type {\n            name\n          }\n          clearance\n          _clearance {\n            name\n          }\n          reapply\n          status\n          _status {\n            name\n          }\n          substatus\n          _substatus {\n            name\n            color\n          }\n          rejection_date\n        }\n      }\n    }\n  }\n": types.CandidateDetailsByIdDocument,
    "\n  query candidateExhibitById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          exhibit {\n            sent_for_review\n            hm_approved\n            contracts_approved\n            signed\n            pmo_approved\n          }\n        }\n      }\n    }\n  }\n": types.CandidateExhibitByIdDocument,
    "\n  query candidateInterviewById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          round_1\n          round_2\n          round_3\n        }\n      }\n    }\n  }\n": types.CandidateInterviewByIdDocument,
    "\n  query candidateNotesById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          notes\n        }\n      }\n    }\n  }\n": types.CandidateNotesByIdDocument,
    "\n  query candidateOfferLetterById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          offer_letter {\n            extended\n            signed\n          }\n        }\n      }\n    }\n  }\n": types.CandidateOfferLetterByIdDocument,
    "\n  query candidatePositionDetailsById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          position\n          _position {\n            _id\n            id\n            title\n            _contract {\n              name\n            }\n          }\n          distribution\n          _distribution {\n            _id\n            _category {\n              name\n            }\n            _subcategory {\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n": types.CandidatePositionDetailsByIdDocument,
    "\n  query candidateRatesById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          bill_rate {\n            min\n            max\n          }\n          salary {\n            min\n            max\n          }\n          _position {\n            bill_rate {\n              min\n              max\n            }\n            salary {\n              min\n              max\n            }\n          }\n        }\n      }\n    }\n  }\n": types.CandidateRatesByIdDocument,
    "\n  query candidateResumeReviewById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          resume {\n            sent\n            reviewed\n            reviewed_by\n            _reviewed_by {\n              name\n            }\n          }\n          rank\n          _rank {\n            name\n          }\n        }\n      }\n    }\n  }\n": types.CandidateResumeReviewByIdDocument,
    "\n  query candidates($filter: FilterFindManyCandidateInput) {\n    cat {\n      candidate {\n        many(filter: $filter) {\n          _id\n          attachments_count\n          name {\n            first\n            last\n          }\n          _distribution {\n            _category {\n              name\n            }\n            _subcategory {\n              _id\n              name\n            }\n          }\n          _status {\n            name\n          }\n          _substatus {\n            name\n            color\n          }\n          bill_rate {\n            min\n            max\n          }\n          salary {\n            min\n            max\n          }\n          round_1\n          round_2\n          round_3\n        }\n      }\n    }\n  }\n": types.CandidatesDocument,
    "\n  query candidatesByName($filter: FilterFindManyCandidateInput) {\n    cat {\n      candidate {\n        many(filter: $filter) {\n          _id\n          name {\n            first\n            last\n          }\n          _position {\n            id\n            title\n          }\n        }\n      }\n    }\n  }\n": types.CandidatesByNameDocument,
    "\n  mutation mutateCandidateById($id: MongoID!, $record: UpdateByIdCandidateInput!) {\n    cat {\n      candidate {\n        updateById(_id: $id, record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n": types.MutateCandidateByIdDocument,
    "\n  mutation mutateCreateCandidate($record: CreateOneCandidateInput!) {\n    cat {\n      candidate {\n        createOne(record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n": types.MutateCreateCandidateDocument,
    "\n  query distributionCreatedUpdatedById($id: MongoID!) {\n    cat {\n      distribution {\n        byId(_id: $id) {\n          _id\n          _createdBy {\n            name\n          }\n          _updatedBy {\n            name\n          }\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n": types.DistributionCreatedUpdatedByIdDocument,
    "\n  query distributionDetailsById($id: MongoID!) {\n    cat {\n      distribution {\n        byId(_id: $id) {\n          _id\n          category\n          _category {\n            name\n          }\n          subcategory\n          _subcategory {\n            name\n          }\n          position\n          _position {\n            _id\n            id\n            title\n            _contract {\n              name\n            }\n          }\n          date_posted\n        }\n      }\n    }\n  }\n": types.DistributionDetailsByIdDocument,
    "\n  query distributionNotesById($id: MongoID!) {\n    cat {\n      distribution {\n        byId(_id: $id) {\n          _id\n          notes\n        }\n      }\n    }\n  }\n": types.DistributionNotesByIdDocument,
    "\n  query distributionsByPositionId($filter: FilterFindManyDistributionInput) {\n    cat {\n      distribution {\n        many(filter: $filter) {\n          _id\n          attachments_count\n          _category {\n            name\n          }\n          _subcategory {\n            _id\n            name\n          }\n          date_posted\n          candidate_count\n        }\n      }\n    }\n  }\n": types.DistributionsByPositionIdDocument,
    "\n  query distributionsOverview($filter: FilterFindManyDistributionInput, $limit: Int) {\n    cat {\n      distribution {\n        many(filter: $filter, limit: $limit) {\n          _id\n          _category {\n            name\n          }\n          _subcategory {\n            _id\n            name\n          }\n          date_posted\n          candidate_count\n          _position {\n            _id\n            id\n            title\n            bill_rate {\n              max\n            }\n            salary {\n              max\n            }\n            _status {\n              _id\n              name\n            }\n            _contract {\n              _id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n": types.DistributionsOverviewDocument,
    "\n  mutation mutateCreateManyDistributions($records: [CreateManyDistributionInput!]!) {\n    cat {\n      distribution {\n        createMany(records: $records) {\n          recordIds\n        }\n      }\n    }\n  }\n": types.MutateCreateManyDistributionsDocument,
    "\n  mutation mutateDistributionById($id: MongoID!, $record: UpdateByIdDistributionInput!) {\n    cat {\n      distribution {\n        updateById(_id: $id, record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n": types.MutateDistributionByIdDocument,
    "\n  query candidateRanks($sort: SortFindManyCandidate_RankInput) {\n    lookups {\n      Candidate_Rank {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n": types.CandidateRanksDocument,
    "\n  query candidateStatuses($sort: SortFindManyCandidate_StatusInput) {\n    lookups {\n      Candidate_Status {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n": types.CandidateStatusesDocument,
    "\n  query candidateSubstatuses($sort: SortFindManyCandidate_SubstatusInput, $filter: FilterFindManyCandidate_SubstatusInput) {\n    lookups {\n      Candidate_Substatus {\n        many(sort: $sort, filter: $filter) {\n          _id\n          name\n        }\n      }\n    }\n  }\n": types.CandidateSubstatusesDocument,
    "\n  query candidateTypes($sort: SortFindManyCandidate_TypeInput) {\n    lookups {\n      Candidate_Type {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n": types.CandidateTypesDocument,
    "\n  query clearances($sort: SortFindManyClearanceInput) {\n    lookups {\n      Clearance {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n": types.ClearancesDocument,
    "\n  query contractTypes($sort: SortFindManyContract_TypeInput) {\n    lookups {\n      Contract_Type {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n": types.ContractTypesDocument,
    "\n  query contracts($sort: SortFindManyCat_ContractInput) {\n    lookups {\n      Cat_Contract {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n": types.ContractsDocument,
    "\n  query distributionCategories($sort: SortFindManyDistribution_CategoryInput) {\n    lookups {\n      Distribution_Category {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n": types.DistributionCategoriesDocument,
    "\n  query distributionSubcategories($filter: FilterFindManyDistribution_SubcategoryInput, $sort: SortFindManyDistribution_SubcategoryInput) {\n    lookups {\n      Distribution_Subcategory{\n        many(filter: $filter, sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n": types.DistributionSubcategoriesDocument,
    "\n  query distributionSubcategoriesAndCategories($filter: FilterFindManyDistribution_SubcategoryInput, $sort: SortFindManyDistribution_SubcategoryInput) {\n    lookups {\n      Distribution_Subcategory{\n        many(filter: $filter, sort: $sort) {\n          _id\n          name\n          _category {\n            _id\n            name\n          }\n        }\n      }\n    }\n  }\n": types.DistributionSubcategoriesAndCategoriesDocument,
    "\n  query hiringManagers($sort: SortFindManyHiring_ManagerInput) {\n    lookups {\n      Hiring_Manager {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n": types.HiringManagersDocument,
    "\n  query positionFilterLookups (\n    $ContractFilter: FilterFindManyCat_ContractInput,\n    $StatusFilter: FilterFindManyPosition_StatusInput\n    $ContractSort: SortFindManyCat_ContractInput,\n    $StatusSort: SortFindManyPosition_StatusInput\n  ) {\n    lookups {\n      Cat_Contract {\n        many(filter: $ContractFilter, sort: $ContractSort) {\n          _id\n          name\n        }\n      }\n      Position_Status {\n        many(filter: $StatusFilter, sort: $StatusSort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n": types.PositionFilterLookupsDocument,
    "\n  query positionPriorities($sort: SortFindManyPosition_PriorityInput) {\n    lookups {\n      Position_Priority {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n": types.PositionPrioritiesDocument,
    "\n  query positionStatuses ($sort: SortFindManyPosition_StatusInput) {\n    lookups {\n      Position_Status {\n        many(sort: $sort) {\n          _id\n          name\n          order\n        }\n      }\n    }\n  }\n": types.PositionStatusesDocument,
    "\n  query programManagers($sort: SortFindManyProgram_ManagerInput) {\n    lookups {\n      Program_Manager {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n": types.ProgramManagersDocument,
    "\n  mutation mutateCreatePosition($record: CreateOnePositionInput!) {\n    cat {\n      position {\n        createOne(record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n": types.MutateCreatePositionDocument,
    "\n  mutation mutatePositionById($id: Int!, $record: UpdateByIdPositionInput!) {\n    cat {\n      position {\n        updateById(_id: $id, record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n": types.MutatePositionByIdDocument,
    "\n  query positionCreatedUpdatedById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          _createdBy {\n            name\n          }\n          _updatedBy {\n            name\n          }\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n": types.PositionCreatedUpdatedByIdDocument,
    "\n  query positionDetailsById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          id\n          title\n          status\n          _status {\n            name\n          }\n          filled_date\n          closed_date\n          program_manager\n          _program_manager {\n            name\n          }\n          hiring_manager\n          _hiring_manager {\n            name\n          }\n          required_clearance\n          _required_clearance {\n            name\n          }\n          priority\n          _priority {\n            name\n          }\n          location\n          contract_type\n          _contract_type {\n            name\n          }\n          keywords\n          contract\n          _contract {\n            name\n          }\n          backfill\n          direct_hire\n          temp_to_perm\n        }\n      }\n    }\n  }\n": types.PositionDetailsByIdDocument,
    "\n  query positionJobDescById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          job_desc_received\n          job_desc_finalized\n          job_desc_posted\n        }\n      }\n    }\n  }\n": types.PositionJobDescByIdDocument,
    "\n  query positionNotesById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          notes\n        }\n      }\n    }\n  }\n": types.PositionNotesByIdDocument,
    "\n  query positionRatesById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          pricing {\n            received\n            requested\n          }\n          bill_rate {\n            min\n            max\n          }\n          salary {\n            min\n            max\n          }\n        }\n      }\n    }\n  }\n": types.PositionRatesByIdDocument,
    "\n  query positions($filter: FilterFindManyPositionInput, $limit: Int, $sort: SortFindManyPositionInput) {\n    cat {\n      position {\n        many(filter: $filter, limit: $limit, sort: $sort) {\n          _id\n          id\n          title\n          _status {\n            name\n          }\n          _contract {\n            name\n          }\n          _program_manager {\n            name\n          }\n          bill_rate {\n            min\n            max\n          }\n          salary {\n            min\n            max\n          }\n        }\n      }\n    }\n  }\n": types.PositionsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query attachmentById($id: MongoID!) {\n    attachments {\n      byId(_id: $id) {\n        _id,\n        filename\n        type\n        subtype\n      }\n    }\n  }\n"): (typeof documents)["\n  query attachmentById($id: MongoID!) {\n    attachments {\n      byId(_id: $id) {\n        _id,\n        filename\n        type\n        subtype\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Search($page: Int, $perPage: Int, $filter: GlobalSearchFilterArgs) {\n    search(page: $page, perPage: $perPage, filter: $filter) {\n      count\n      items {\n        _id\n        badges {\n          title\n          value\n        }\n        category\n        score\n        title\n        url\n      }\n      pageInfo {\n        currentPage\n        hasNextPage\n        hasPreviousPage\n        itemCount\n        pageCount\n        perPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query Search($page: Int, $perPage: Int, $filter: GlobalSearchFilterArgs) {\n    search(page: $page, perPage: $perPage, filter: $filter) {\n      count\n      items {\n        _id\n        badges {\n          title\n          value\n        }\n        category\n        score\n        title\n        url\n      }\n      pageInfo {\n        currentPage\n        hasNextPage\n        hasPreviousPage\n        itemCount\n        pageCount\n        perPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserSelf {\n    user {\n      self {\n        _id\n        email\n        _role {\n          defaultPage\n          title\n          _permissions {\n            _id\n            key\n          }\n        }\n        defaultPage\n        createdBy\n        updatedBy\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserSelf {\n    user {\n      self {\n        _id\n        email\n        _role {\n          defaultPage\n          title\n          _permissions {\n            _id\n            key\n          }\n        }\n        defaultPage\n        createdBy\n        updatedBy\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation mutateAnalyticsCreateOne($record: CreateOneAnalyticsInput!) {\n    analytics {\n      createOne(record: $record) {\n        recordId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation mutateAnalyticsCreateOne($record: CreateOneAnalyticsInput!) {\n    analytics {\n      createOne(record: $record) {\n        recordId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query attachmentSubtypes($filter: FilterFindManyAttachment_SubtypeInput) {\n    attachmentSubtypes {\n      many(filter: $filter) {\n        _id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query attachmentSubtypes($filter: FilterFindManyAttachment_SubtypeInput) {\n    attachmentSubtypes {\n      many(filter: $filter) {\n        _id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query attachments($filter: FilterFindManyAttachmentInput, $sort: SortFindManyAttachmentInput, $limit: Int) {\n    attachments {\n      many(filter: $filter, sort: $sort, limit: $limit) {\n        _id\n        url\n        filename\n        _subtype {\n          name\n        }\n        link\n        _link {\n          ... on Position {\n            id\n            title\n            keywords\n            _contract {\n              name\n            }\n          }\n        }\n        updatedAt\n        _updatedBy {\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query attachments($filter: FilterFindManyAttachmentInput, $sort: SortFindManyAttachmentInput, $limit: Int) {\n    attachments {\n      many(filter: $filter, sort: $sort, limit: $limit) {\n        _id\n        url\n        filename\n        _subtype {\n          name\n        }\n        link\n        _link {\n          ... on Position {\n            id\n            title\n            keywords\n            _contract {\n              name\n            }\n          }\n        }\n        updatedAt\n        _updatedBy {\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation mutateRemoveAttachment($id: MongoID!) {\n    attachments {\n      removeById(_id: $id) {\n        recordId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation mutateRemoveAttachment($id: MongoID!) {\n    attachments {\n      removeById(_id: $id) {\n        recordId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation mutateUpdateAttachment($id: MongoID!, $record: UpdateByIdAttachmentInput!) {\n    attachments {\n      updateById(_id: $id, record: $record) {\n        recordId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation mutateUpdateAttachment($id: MongoID!, $record: UpdateByIdAttachmentInput!) {\n    attachments {\n      updateById(_id: $id, record: $record) {\n        recordId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidateContractualDocumentById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          nda_signed {\n            staffing_agency\n            candidate\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidateContractualDocumentById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          nda_signed {\n            staffing_agency\n            candidate\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidateCreatedUpdatedById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          _createdBy {\n            name\n          }\n          _updatedBy {\n            name\n          }\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidateCreatedUpdatedById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          _createdBy {\n            name\n          }\n          _updatedBy {\n            name\n          }\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidateDetailsById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          location\n          name {\n            first\n            last\n          }\n          received_on\n          type\n          _type {\n            name\n          }\n          clearance\n          _clearance {\n            name\n          }\n          reapply\n          status\n          _status {\n            name\n          }\n          substatus\n          _substatus {\n            name\n            color\n          }\n          rejection_date\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidateDetailsById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          location\n          name {\n            first\n            last\n          }\n          received_on\n          type\n          _type {\n            name\n          }\n          clearance\n          _clearance {\n            name\n          }\n          reapply\n          status\n          _status {\n            name\n          }\n          substatus\n          _substatus {\n            name\n            color\n          }\n          rejection_date\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidateExhibitById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          exhibit {\n            sent_for_review\n            hm_approved\n            contracts_approved\n            signed\n            pmo_approved\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidateExhibitById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          exhibit {\n            sent_for_review\n            hm_approved\n            contracts_approved\n            signed\n            pmo_approved\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidateInterviewById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          round_1\n          round_2\n          round_3\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidateInterviewById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          round_1\n          round_2\n          round_3\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidateNotesById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          notes\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidateNotesById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          notes\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidateOfferLetterById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          offer_letter {\n            extended\n            signed\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidateOfferLetterById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          offer_letter {\n            extended\n            signed\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidatePositionDetailsById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          position\n          _position {\n            _id\n            id\n            title\n            _contract {\n              name\n            }\n          }\n          distribution\n          _distribution {\n            _id\n            _category {\n              name\n            }\n            _subcategory {\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidatePositionDetailsById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          position\n          _position {\n            _id\n            id\n            title\n            _contract {\n              name\n            }\n          }\n          distribution\n          _distribution {\n            _id\n            _category {\n              name\n            }\n            _subcategory {\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidateRatesById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          bill_rate {\n            min\n            max\n          }\n          salary {\n            min\n            max\n          }\n          _position {\n            bill_rate {\n              min\n              max\n            }\n            salary {\n              min\n              max\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidateRatesById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          bill_rate {\n            min\n            max\n          }\n          salary {\n            min\n            max\n          }\n          _position {\n            bill_rate {\n              min\n              max\n            }\n            salary {\n              min\n              max\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidateResumeReviewById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          resume {\n            sent\n            reviewed\n            reviewed_by\n            _reviewed_by {\n              name\n            }\n          }\n          rank\n          _rank {\n            name\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidateResumeReviewById($id: MongoID!) {\n    cat {\n      candidate {\n        byId(_id: $id) {\n          _id\n          resume {\n            sent\n            reviewed\n            reviewed_by\n            _reviewed_by {\n              name\n            }\n          }\n          rank\n          _rank {\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidates($filter: FilterFindManyCandidateInput) {\n    cat {\n      candidate {\n        many(filter: $filter) {\n          _id\n          attachments_count\n          name {\n            first\n            last\n          }\n          _distribution {\n            _category {\n              name\n            }\n            _subcategory {\n              _id\n              name\n            }\n          }\n          _status {\n            name\n          }\n          _substatus {\n            name\n            color\n          }\n          bill_rate {\n            min\n            max\n          }\n          salary {\n            min\n            max\n          }\n          round_1\n          round_2\n          round_3\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidates($filter: FilterFindManyCandidateInput) {\n    cat {\n      candidate {\n        many(filter: $filter) {\n          _id\n          attachments_count\n          name {\n            first\n            last\n          }\n          _distribution {\n            _category {\n              name\n            }\n            _subcategory {\n              _id\n              name\n            }\n          }\n          _status {\n            name\n          }\n          _substatus {\n            name\n            color\n          }\n          bill_rate {\n            min\n            max\n          }\n          salary {\n            min\n            max\n          }\n          round_1\n          round_2\n          round_3\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidatesByName($filter: FilterFindManyCandidateInput) {\n    cat {\n      candidate {\n        many(filter: $filter) {\n          _id\n          name {\n            first\n            last\n          }\n          _position {\n            id\n            title\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidatesByName($filter: FilterFindManyCandidateInput) {\n    cat {\n      candidate {\n        many(filter: $filter) {\n          _id\n          name {\n            first\n            last\n          }\n          _position {\n            id\n            title\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation mutateCandidateById($id: MongoID!, $record: UpdateByIdCandidateInput!) {\n    cat {\n      candidate {\n        updateById(_id: $id, record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation mutateCandidateById($id: MongoID!, $record: UpdateByIdCandidateInput!) {\n    cat {\n      candidate {\n        updateById(_id: $id, record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation mutateCreateCandidate($record: CreateOneCandidateInput!) {\n    cat {\n      candidate {\n        createOne(record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation mutateCreateCandidate($record: CreateOneCandidateInput!) {\n    cat {\n      candidate {\n        createOne(record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query distributionCreatedUpdatedById($id: MongoID!) {\n    cat {\n      distribution {\n        byId(_id: $id) {\n          _id\n          _createdBy {\n            name\n          }\n          _updatedBy {\n            name\n          }\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query distributionCreatedUpdatedById($id: MongoID!) {\n    cat {\n      distribution {\n        byId(_id: $id) {\n          _id\n          _createdBy {\n            name\n          }\n          _updatedBy {\n            name\n          }\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query distributionDetailsById($id: MongoID!) {\n    cat {\n      distribution {\n        byId(_id: $id) {\n          _id\n          category\n          _category {\n            name\n          }\n          subcategory\n          _subcategory {\n            name\n          }\n          position\n          _position {\n            _id\n            id\n            title\n            _contract {\n              name\n            }\n          }\n          date_posted\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query distributionDetailsById($id: MongoID!) {\n    cat {\n      distribution {\n        byId(_id: $id) {\n          _id\n          category\n          _category {\n            name\n          }\n          subcategory\n          _subcategory {\n            name\n          }\n          position\n          _position {\n            _id\n            id\n            title\n            _contract {\n              name\n            }\n          }\n          date_posted\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query distributionNotesById($id: MongoID!) {\n    cat {\n      distribution {\n        byId(_id: $id) {\n          _id\n          notes\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query distributionNotesById($id: MongoID!) {\n    cat {\n      distribution {\n        byId(_id: $id) {\n          _id\n          notes\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query distributionsByPositionId($filter: FilterFindManyDistributionInput) {\n    cat {\n      distribution {\n        many(filter: $filter) {\n          _id\n          attachments_count\n          _category {\n            name\n          }\n          _subcategory {\n            _id\n            name\n          }\n          date_posted\n          candidate_count\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query distributionsByPositionId($filter: FilterFindManyDistributionInput) {\n    cat {\n      distribution {\n        many(filter: $filter) {\n          _id\n          attachments_count\n          _category {\n            name\n          }\n          _subcategory {\n            _id\n            name\n          }\n          date_posted\n          candidate_count\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query distributionsOverview($filter: FilterFindManyDistributionInput, $limit: Int) {\n    cat {\n      distribution {\n        many(filter: $filter, limit: $limit) {\n          _id\n          _category {\n            name\n          }\n          _subcategory {\n            _id\n            name\n          }\n          date_posted\n          candidate_count\n          _position {\n            _id\n            id\n            title\n            bill_rate {\n              max\n            }\n            salary {\n              max\n            }\n            _status {\n              _id\n              name\n            }\n            _contract {\n              _id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query distributionsOverview($filter: FilterFindManyDistributionInput, $limit: Int) {\n    cat {\n      distribution {\n        many(filter: $filter, limit: $limit) {\n          _id\n          _category {\n            name\n          }\n          _subcategory {\n            _id\n            name\n          }\n          date_posted\n          candidate_count\n          _position {\n            _id\n            id\n            title\n            bill_rate {\n              max\n            }\n            salary {\n              max\n            }\n            _status {\n              _id\n              name\n            }\n            _contract {\n              _id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation mutateCreateManyDistributions($records: [CreateManyDistributionInput!]!) {\n    cat {\n      distribution {\n        createMany(records: $records) {\n          recordIds\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation mutateCreateManyDistributions($records: [CreateManyDistributionInput!]!) {\n    cat {\n      distribution {\n        createMany(records: $records) {\n          recordIds\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation mutateDistributionById($id: MongoID!, $record: UpdateByIdDistributionInput!) {\n    cat {\n      distribution {\n        updateById(_id: $id, record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation mutateDistributionById($id: MongoID!, $record: UpdateByIdDistributionInput!) {\n    cat {\n      distribution {\n        updateById(_id: $id, record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidateRanks($sort: SortFindManyCandidate_RankInput) {\n    lookups {\n      Candidate_Rank {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidateRanks($sort: SortFindManyCandidate_RankInput) {\n    lookups {\n      Candidate_Rank {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidateStatuses($sort: SortFindManyCandidate_StatusInput) {\n    lookups {\n      Candidate_Status {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidateStatuses($sort: SortFindManyCandidate_StatusInput) {\n    lookups {\n      Candidate_Status {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidateSubstatuses($sort: SortFindManyCandidate_SubstatusInput, $filter: FilterFindManyCandidate_SubstatusInput) {\n    lookups {\n      Candidate_Substatus {\n        many(sort: $sort, filter: $filter) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidateSubstatuses($sort: SortFindManyCandidate_SubstatusInput, $filter: FilterFindManyCandidate_SubstatusInput) {\n    lookups {\n      Candidate_Substatus {\n        many(sort: $sort, filter: $filter) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query candidateTypes($sort: SortFindManyCandidate_TypeInput) {\n    lookups {\n      Candidate_Type {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query candidateTypes($sort: SortFindManyCandidate_TypeInput) {\n    lookups {\n      Candidate_Type {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query clearances($sort: SortFindManyClearanceInput) {\n    lookups {\n      Clearance {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query clearances($sort: SortFindManyClearanceInput) {\n    lookups {\n      Clearance {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query contractTypes($sort: SortFindManyContract_TypeInput) {\n    lookups {\n      Contract_Type {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query contractTypes($sort: SortFindManyContract_TypeInput) {\n    lookups {\n      Contract_Type {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query contracts($sort: SortFindManyCat_ContractInput) {\n    lookups {\n      Cat_Contract {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query contracts($sort: SortFindManyCat_ContractInput) {\n    lookups {\n      Cat_Contract {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query distributionCategories($sort: SortFindManyDistribution_CategoryInput) {\n    lookups {\n      Distribution_Category {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query distributionCategories($sort: SortFindManyDistribution_CategoryInput) {\n    lookups {\n      Distribution_Category {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query distributionSubcategories($filter: FilterFindManyDistribution_SubcategoryInput, $sort: SortFindManyDistribution_SubcategoryInput) {\n    lookups {\n      Distribution_Subcategory{\n        many(filter: $filter, sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query distributionSubcategories($filter: FilterFindManyDistribution_SubcategoryInput, $sort: SortFindManyDistribution_SubcategoryInput) {\n    lookups {\n      Distribution_Subcategory{\n        many(filter: $filter, sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query distributionSubcategoriesAndCategories($filter: FilterFindManyDistribution_SubcategoryInput, $sort: SortFindManyDistribution_SubcategoryInput) {\n    lookups {\n      Distribution_Subcategory{\n        many(filter: $filter, sort: $sort) {\n          _id\n          name\n          _category {\n            _id\n            name\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query distributionSubcategoriesAndCategories($filter: FilterFindManyDistribution_SubcategoryInput, $sort: SortFindManyDistribution_SubcategoryInput) {\n    lookups {\n      Distribution_Subcategory{\n        many(filter: $filter, sort: $sort) {\n          _id\n          name\n          _category {\n            _id\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query hiringManagers($sort: SortFindManyHiring_ManagerInput) {\n    lookups {\n      Hiring_Manager {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query hiringManagers($sort: SortFindManyHiring_ManagerInput) {\n    lookups {\n      Hiring_Manager {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query positionFilterLookups (\n    $ContractFilter: FilterFindManyCat_ContractInput,\n    $StatusFilter: FilterFindManyPosition_StatusInput\n    $ContractSort: SortFindManyCat_ContractInput,\n    $StatusSort: SortFindManyPosition_StatusInput\n  ) {\n    lookups {\n      Cat_Contract {\n        many(filter: $ContractFilter, sort: $ContractSort) {\n          _id\n          name\n        }\n      }\n      Position_Status {\n        many(filter: $StatusFilter, sort: $StatusSort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query positionFilterLookups (\n    $ContractFilter: FilterFindManyCat_ContractInput,\n    $StatusFilter: FilterFindManyPosition_StatusInput\n    $ContractSort: SortFindManyCat_ContractInput,\n    $StatusSort: SortFindManyPosition_StatusInput\n  ) {\n    lookups {\n      Cat_Contract {\n        many(filter: $ContractFilter, sort: $ContractSort) {\n          _id\n          name\n        }\n      }\n      Position_Status {\n        many(filter: $StatusFilter, sort: $StatusSort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query positionPriorities($sort: SortFindManyPosition_PriorityInput) {\n    lookups {\n      Position_Priority {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query positionPriorities($sort: SortFindManyPosition_PriorityInput) {\n    lookups {\n      Position_Priority {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query positionStatuses ($sort: SortFindManyPosition_StatusInput) {\n    lookups {\n      Position_Status {\n        many(sort: $sort) {\n          _id\n          name\n          order\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query positionStatuses ($sort: SortFindManyPosition_StatusInput) {\n    lookups {\n      Position_Status {\n        many(sort: $sort) {\n          _id\n          name\n          order\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query programManagers($sort: SortFindManyProgram_ManagerInput) {\n    lookups {\n      Program_Manager {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query programManagers($sort: SortFindManyProgram_ManagerInput) {\n    lookups {\n      Program_Manager {\n        many(sort: $sort) {\n          _id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation mutateCreatePosition($record: CreateOnePositionInput!) {\n    cat {\n      position {\n        createOne(record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation mutateCreatePosition($record: CreateOnePositionInput!) {\n    cat {\n      position {\n        createOne(record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation mutatePositionById($id: Int!, $record: UpdateByIdPositionInput!) {\n    cat {\n      position {\n        updateById(_id: $id, record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation mutatePositionById($id: Int!, $record: UpdateByIdPositionInput!) {\n    cat {\n      position {\n        updateById(_id: $id, record: $record) {\n          recordId\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query positionCreatedUpdatedById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          _createdBy {\n            name\n          }\n          _updatedBy {\n            name\n          }\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query positionCreatedUpdatedById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          _createdBy {\n            name\n          }\n          _updatedBy {\n            name\n          }\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query positionDetailsById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          id\n          title\n          status\n          _status {\n            name\n          }\n          filled_date\n          closed_date\n          program_manager\n          _program_manager {\n            name\n          }\n          hiring_manager\n          _hiring_manager {\n            name\n          }\n          required_clearance\n          _required_clearance {\n            name\n          }\n          priority\n          _priority {\n            name\n          }\n          location\n          contract_type\n          _contract_type {\n            name\n          }\n          keywords\n          contract\n          _contract {\n            name\n          }\n          backfill\n          direct_hire\n          temp_to_perm\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query positionDetailsById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          id\n          title\n          status\n          _status {\n            name\n          }\n          filled_date\n          closed_date\n          program_manager\n          _program_manager {\n            name\n          }\n          hiring_manager\n          _hiring_manager {\n            name\n          }\n          required_clearance\n          _required_clearance {\n            name\n          }\n          priority\n          _priority {\n            name\n          }\n          location\n          contract_type\n          _contract_type {\n            name\n          }\n          keywords\n          contract\n          _contract {\n            name\n          }\n          backfill\n          direct_hire\n          temp_to_perm\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query positionJobDescById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          job_desc_received\n          job_desc_finalized\n          job_desc_posted\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query positionJobDescById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          job_desc_received\n          job_desc_finalized\n          job_desc_posted\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query positionNotesById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          notes\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query positionNotesById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          notes\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query positionRatesById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          pricing {\n            received\n            requested\n          }\n          bill_rate {\n            min\n            max\n          }\n          salary {\n            min\n            max\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query positionRatesById($id: Int!) {\n    cat {\n      position {\n        byId(_id: $id) {\n          _id\n          pricing {\n            received\n            requested\n          }\n          bill_rate {\n            min\n            max\n          }\n          salary {\n            min\n            max\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query positions($filter: FilterFindManyPositionInput, $limit: Int, $sort: SortFindManyPositionInput) {\n    cat {\n      position {\n        many(filter: $filter, limit: $limit, sort: $sort) {\n          _id\n          id\n          title\n          _status {\n            name\n          }\n          _contract {\n            name\n          }\n          _program_manager {\n            name\n          }\n          bill_rate {\n            min\n            max\n          }\n          salary {\n            min\n            max\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query positions($filter: FilterFindManyPositionInput, $limit: Int, $sort: SortFindManyPositionInput) {\n    cat {\n      position {\n        many(filter: $filter, limit: $limit, sort: $sort) {\n          _id\n          id\n          title\n          _status {\n            name\n          }\n          _contract {\n            name\n          }\n          _program_manager {\n            name\n          }\n          bill_rate {\n            min\n            max\n          }\n          salary {\n            min\n            max\n          }\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;