import React from 'react'
import { Formik } from 'formik'
import { UseMutateDistributionByIdReturn, distributionNotesByIdDocument, useMutateDistributionById, useDistributionNotesById } from 'src/queries'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { MutateDistributionByIdMutation } from 'src/gql/graphql'
import Form from './Form'
import { pick } from 'lodash'

type Values = Parameters<UseMutateDistributionByIdReturn['mutate']>[0]['record']

interface FormContextProps {
  id: string
  toggle: () => void
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const { id, toggle } = props
  const distribution = useDistributionNotesById(id)
  if (distribution === undefined) {
    throw new Error('Could not load distribution!')
  }
  const update = useMutateDistributionById()
  const initialState: Values = pick(distribution, [
    'notes'
  ])
  const invalidateDistribution = useInvalidateQueryByDocument(distributionNotesByIdDocument)
  const handleSuccess = (data: MutateDistributionByIdMutation): void => {
    const id = data.cat?.distribution?.updateById?.recordId
    if (typeof id === 'string') {
      void invalidateDistribution({ id })
    }
    toggle()
  }
  return (
    <Formik
      initialValues={initialState}
      onSubmit={async (data) => {
        const record = { ...data }
        update.mutate({ id, record }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <Form toggle={toggle} isLoading={update.isLoading} hasError={update.isError} />
      )}
    </Formik>
  )
}

export default FormContext
