import React from 'react'

import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import { Link } from 'react-router-dom'
import { CandidatePositionDetailsById } from 'src/queries'

interface ListProps {
  candidate: CandidatePositionDetailsById | undefined
}

const List: React.FC<ListProps> = (props) => {
  const { candidate } = props
  const position = candidate?._position ?? undefined
  const distribution = candidate?._distribution ?? undefined
  const section: Array<Omit<ItemProps, 'id'>> = [
    {
      title: 'Position Number',
      value: position !== undefined
        ? (
          <Link to={`/cat/positions/${position._id}`}>{position?.id}</Link>
          )
        : null
    },
    {
      title: 'Position Title',
      value: position !== undefined
        ? (
          <Link to={`/cat/positions/${position._id}`}>{position?.title}</Link>
          )
        : null
    },
    { title: 'Contract', value: position?._contract?.name },
    {
      title: 'Distribution Subcategory',
      value: distribution !== undefined
        ? (
          <Link to={`/cat/distributions/${String(distribution._id)}`}>{distribution._subcategory?.name}</Link>
          )
        : null
    }
  ]
  const dlProps = {
    className: 'row-cols-1',
    suspended: candidate === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  return (
    <DescriptionList {...dlProps}>
      {section.map(item => {
        const key = item.title.toLowerCase().replace(' ', '-')
        return <Item {...item} key={key} id={key} />
      })}
    </DescriptionList>
  )
}

export default List
