import { graphql } from 'src/gql'
import { SortFindManyCat_ContractInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQLDropdownOptions } from 'src/queries/cat/lookups/helpers'

const document = graphql(/* graphql */`
  query contracts($sort: SortFindManyCat_ContractInput) {
    lookups {
      Cat_Contract {
        many(sort: $sort) {
          _id
          name
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type Contracts = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Cat_Contract']>['many']>

const useContracts = useGraphQLDropdownOptions(
  document,
  'lookups.Cat_Contract.many',
  { sort: SortFindManyCat_ContractInput.NameAsc }
)

export { useContracts, document as contractsDocument }
