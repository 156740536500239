import { useEffect, useState } from 'react'
import { PositionsQueryVariables } from 'src/gql/graphql'
import { FilterValues, FiltersProps } from '.'
import { ButtonProps } from 'reactstrap'

export interface UseFilterStateResults {
  filterProps: FiltersProps
  queryFilters: PositionsQueryVariables['filter']
  buttonColor: ButtonProps['color']
}

const LOCAL_STORAGE_KEY = 'position-list-filters'

const useFilterState = (): UseFilterStateResults => {
  const [filtersOpen, setFiltersOpen] = useState(false)
  const toggleFilters = (): void => setFiltersOpen(o => !o)
  const filterStorage = localStorage.getItem(LOCAL_STORAGE_KEY)
  const [filters, setFilters] = useState<FilterValues>(
    filterStorage !== null
      ? JSON.parse(filterStorage)
      : {
          contract: [],
          status: []
        }
  )
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(filters))
  }, [filters])
  const buttonColor: ButtonProps['color'] = filters.contract.length > 0 || filters.status.length > 0 ? 'success' : 'light'
  const queryFilters: PositionsQueryVariables['filter'] = { _operators: {} }
  if (filters?.contract.length > 0) {
    queryFilters._operators = queryFilters._operators ?? {}
    queryFilters._operators.contract = {
      in: filters.contract
    }
  }
  if (filters?.status.length > 0) {
    queryFilters._operators = queryFilters._operators ?? {}
    queryFilters._operators.status = {
      in: filters.status
    }
  }
  const handleFilterChange: FiltersProps['onSubmit'] = (values) => setFilters(values)
  return {
    filterProps: {
      isOpen: filtersOpen,
      toggleOpen: toggleFilters,
      onSubmit: handleFilterChange,
      initialState: filters
    },
    queryFilters,
    buttonColor
  }
}

export default useFilterState
