import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query candidatePositionDetailsById($id: MongoID!) {
    cat {
      candidate {
        byId(_id: $id) {
          _id
          position
          _position {
            _id
            id
            title
            _contract {
              name
            }
          }
          distribution
          _distribution {
            _id
            _category {
              name
            }
            _subcategory {
              name
            }
          }
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type CandidatePositionDetailsById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['candidate']>['byId']>

const useCandidatePositionDetailsById = (id: string): CandidatePositionDetailsById | undefined => {
  const { data } = useGraphQL(document, { id }, {
    enabled: typeof id === 'string'
  })
  const candidate = data?.cat?.candidate?.byId ?? undefined
  return candidate
}

export { useCandidatePositionDetailsById, document as candidatePositionDetailsByIdDocument }
