import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query positionDetailsById($id: Int!) {
    cat {
      position {
        byId(_id: $id) {
          _id
          id
          title
          status
          _status {
            name
          }
          filled_date
          closed_date
          program_manager
          _program_manager {
            name
          }
          hiring_manager
          _hiring_manager {
            name
          }
          required_clearance
          _required_clearance {
            name
          }
          priority
          _priority {
            name
          }
          location
          contract_type
          _contract_type {
            name
          }
          keywords
          contract
          _contract {
            name
          }
          backfill
          direct_hire
          temp_to_perm
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type PositionDetailsById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['position']>['byId']>

const usePositionDetailsById = (id: number): PositionDetailsById | undefined => {
  const { data } = useGraphQL(document, { id }, {
    enabled: typeof id === 'number'
  })
  const position = data?.cat?.position?.byId ?? undefined
  return position
}

export { usePositionDetailsById, document as positionDetailsByIdDocument }
