import React from 'react'
import InputBase from './InputBase'
import { FieldHookConfig, useField } from 'formik'
import { Input } from 'reactstrap'
import { getFieldError } from 'src/helpers/formHelpers'

export interface DropdownOption {
  value: React.OptionHTMLAttributes<HTMLOptionElement>['value']
  label: string
}

type OmitBetterStrict<T, K extends keyof T> = T extends any ? Pick<T, Exclude<keyof T, K>> : never // Fix some weird union stuff with TS
export type DropdownProps = OmitBetterStrict<FieldHookConfig<any>, 'onChange'> & {
  label: string
  options: DropdownOption[]
  blankOption?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  formatValue?: (value: any) => any
  required?: boolean
}
const Dropdown: React.FC<DropdownProps> = (props) => {
  const { options, onChange, formatValue, blankOption = true, label, required, ...rest } = props
  const [field, meta, helpers] = useField({ ...rest })
  const { error, hasError } = getFieldError(meta)
  const value = typeof formatValue === 'function' ? formatValue(field.value ?? '') : field.value ?? ''
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const result = typeof onChange === 'function' ? onChange(e) : e.target.value
    void helpers.setValue(result)
  }
  return (
    <InputBase label={label} name={field.name} error={error} required={required}>
      <Input type='select' {...field} id={field.name} value={value} onChange={handleChange} invalid={hasError}>
        {blankOption
          ? (
            <option value='' />
            )
          : null}
        {options.map(option => {
          const { value, label } = option
          return <option value={value} key={JSON.stringify(value)}>{label}</option>
        })}
      </Input>
    </InputBase>
  )
}

export default Dropdown
export { Dropdown }
