import React from 'react'
import { usePositionNotesById } from 'src/queries'

interface BodyProps {
  id: number
}

const Body: React.FC<BodyProps> = (props) => {
  const { id } = props
  const position = usePositionNotesById(id)
  return (
    <p style={{ whiteSpace: 'pre-wrap' }}>
      {position?.notes}
    </p>
  )
}

export default Body
